<template>
  <form
    autocomplete="off"
    @submit.prevent="submit"
  >
    <div class="row">
      <loading-notification :show="loading" />
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-select
          v-model="selectedRole"
          :label="$t('users.inputs.roleInput')"
          :options="rolesList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          :loading="isLoading.roles"
          text-by="name"
          searchable
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'change-role',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    user: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    notValid () {
      return this.selectedRole === ''
    },
  },
  data () {
    return {
      isLoading: {
        roles: false,
      },
      rolesList: [],
      selectedRole: '',
    }
  },
  watch: {
    user (val) {
      if (val.dcpi_role) {
        this.selectedRole = val.dcpi_role
      }
    },
  },
  created () {
    if (!this.currentUser.can('Users', 'roles')) return
    this.asyncFindRole('')
  },
  methods: {
    asyncFindRole (query) {
      this.asyncFind(query, 'roles', 'users/roles')
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    submit () {
      if (!this.user || !this.user.id) {
        return
      }

      const user = {
        id: this.user.id,
        dcpi_role_id: this.selectedRole.id,
      }
      this.$emit('submit', user)
    },
  },
}
</script>
